import { ChevronRight } from "lucide-react";
import React from "react";
import Container from "./Container";

export default function Hero() {
  return (
    <section className="pt-[6rem] sm:pt-[10rem] pb-10 sm:pb-16">
      <Container className="flex flex-col relative">
        <h1 className="font-medium text-text_secondary text-3xl sm:text-4xl md:text-6xl mb-4 leading-tight">
          I’m <span className="text-text">Owen Kanzler</span> <br /> A Full
          Stack Developer
        </h1>
        <p className="md:text-[18px] max-w-[600px] text-text_secondary mb-6 sm:mb-8">
          Full stack developer and designer based in Minneapolis, crafting
          seamless web experiences with modern technologies.
        </p>
        <div className="py-2 flex gap-4 items-center">
          <a
            href="mailto:oj.kanz1@gmail.com"
            className="px-4 sm:px-6 py-2 text-xs sm:text-sm bg-text text-bg rounded-lg hover:bg-[#fff] transition-all duration-100"
          >
            Contact
          </a>
          <a
            href="./resume.pdf"
            className="text-text_secondary px-4 py-2 hover:bg-bg_secondary rounded-lg text-xs sm:text-sm flex items-center gap-1 transition-all duration-100"
          >
            View My Resume
            <ChevronRight size={16} />
          </a>
        </div>
      </Container>
    </section>
  );
}
