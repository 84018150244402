import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { projects } from "../projects";
import Container from "./Container";

import iconBlack from "../assets/iconBlack.svg";
import iconWhite from "../assets/iconWhite.svg";
import iconGreen from "../assets/iconGreen.svg";
import grid from "../assets/grid.svg";

export default function Projects() {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredItems, setFilteredItems] = useState(projects);

  let filters = [
    "Full Stack",
    "Backend",
    "Frontend",
    "Web Development",
    "UI/UX",
  ];

  const handleFilterButtonClick = (selectedCategory) => {
    if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
    }
  };

  useEffect(() => {
    const filterItems = () => {
      if (selectedFilters.length > 0) {
        const tempItems = projects.filter((item) =>
          selectedFilters.some((filter) => item.category.includes(filter))
        );
        setFilteredItems(tempItems);
      } else {
        setFilteredItems([...projects]);
      }
    };

    filterItems();
  }, [selectedFilters]);

  return (
    <section className="py-10 sm:py-16">
      <Container className="flex flex-col gap-6">
        <div className="flex flex-col items-center">
          <div className="mb-2 sm:mb-4 flex items-center gap-2">
            <img src={iconGreen} loading="lazy" alt="cube" className="w-5" />
            <h3 className="text-xs font-medium text-text_secondary">
              My Selected Projects
            </h3>
          </div>
          <h2 className="text-xl sm:text-3xl md:text-4xl text-center font-medium mb-4 sm:mb-6 md:mb-8 leading-normal">
            From UI/UX to Full Stack Projects <br /> I’m Passionate in
            Everything
          </h2>
          <div className="flex justify-center flex-wrap gap-2 sm:gap-4 pt-2">
            {filters.map((category, i) => (
              <button
                onClick={() => handleFilterButtonClick(category)}
                className={`px-4 py-1 sm:py-2 bg-[#19191999] text-sm rounded-lg hover:bg-bg_secondary transition-all duration-100 flex items-center gap-1 ${
                  selectedFilters?.includes(category)
                    ? "bg-[#eee] text-bg hover:bg-white "
                    : ""
                }`}
                key={i}
              >
                {selectedFilters?.includes(category) ? (
                  <img
                    src={iconBlack}
                    alt="cube"
                    loading="lazy"
                    className="w-5 sm:w-6"
                  />
                ) : (
                  <img
                    src={iconWhite}
                    alt="cube"
                    loading="lazy"
                    className="w-5 sm:w-6"
                  />
                )}
                {category}
              </button>
            ))}
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 w-full items-center">
          {filteredItems.map((project, i) => (
            <Link to={project.path} key={i}>
              <div className="p-4 sm:p-6 flex bg-[#19191999] hover:bg-bg_secondary rounded-xl gap-8">
                <div
                  className="w-[100px] h-[100px] sm:w-[150px] sm:h-[150px] flex items-center justify-center sm:basis-1/3"
                  style={{
                    backgroundImage: `url(${grid})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                >
                  {project.logo && (
                    <img
                      src={project.logo}
                      alt={`${project.name} logo`}
                      className="w-10 sm:w-16 md:w-auto "
                    />
                  )}
                </div>
                <div className="basis-2/3 my-auto">
                  <h3 className="text-lg md:text-2xl font-medium">
                    {project.name}
                  </h3>
                  <p className="text-sm sm:text-base">{project.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </section>
  );
}
