import React from "react";
import Container from "../components/Container";
import { ReactComponent as NameSVG } from "../assets/name.svg";
import { Github, Linkedin } from "lucide-react";

export default function Footer() {
  return (
    <section className="py-16 relative">
      <div className="blur-[200px] w-full h-[125px] rounded-full bg-[#eeeeee40] absolute bottom-0 z-0"></div>

      <Container className="z-10 relative">
        <div className="md:pb-6 border-b border-text_secondary mb-4 sm:mb-6">
          <NameSVG className="max-w-full block" />
        </div>
        <div className="flex items-center gap-4 text-sm">
          <a
            href="./resume.pdf"
            aria-label="Resume"
            className="text-xs hover:text-text_secondary transition-all duration-300"
          >
            Resume
          </a>
          <a
            href="https://www.linkedin.com/in/owenkanzler/"
            aria-label="LinkedIn"
            className="hover:text-text_secondary transition-all duration-300"
          >
            <Linkedin size={14} />
          </a>
          <a
            href="https://github.com/owenkanzler"
            aria-label="Github"
            className="hover:text-text_secondary transition-all duration-300"
          >
            <Github size={14} />
          </a>
        </div>
      </Container>
    </section>
  );
}
