import { LogOut } from "lucide-react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Container from "../components/Container";
import { projects } from "../projects";

const Project = () => {
  const { projectId } = useParams();
  const project = projects.find((p) => p.path === `/${projectId}`);

  if (!project) {
    return (
      <div className="h-screen w-screen flex items-center justify-center font-bold">
        Project not found
      </div>
    );
  }

  return (
    <section className="pt-[12rem] pb-16">
      <Container>
        <div className="flex w-full flex-col lg:flex-row justify-between gap-4">
          <div className="basis-1/2">
            <h2 className="text-4xl font-medium tracking-tight mb-6">
              {project.name}
            </h2>
            <p className="text-text_secondary mb-4">
              {project.fullDescription}
            </p>
            <p className="text-text_secondary mb-4">{project.responsibilies}</p>
            <div className="flex flex-wrap gap-3 mb-6">
              {project.techStack.map((tech, i) => (
                <p
                  className="text-sm text-text_secondary py-1 px-4 bg-bg_secondary rounded"
                  key={i}
                >
                  {tech}
                </p>
              ))}
            </div>
            <div className="flex items-center gap-4">
              {project.links.map((link, i) => (
                <a
                  href={link.url}
                  key={i}
                  className="text-sm underline hover:text-text_secondary transition-all duration-200"
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          {project.images && (
            <div className="flex flex-col basis-1/2 gap-4">
              {project.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={project.name}
                  loading="lazy"
                  className="w-full rounded-xl border border-border"
                />
              ))}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Project;
