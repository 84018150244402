import React from "react";
import Container from "./Container";
import iconGreen from "../assets/iconGreen.svg";

export default function About() {
  return (
    <section className="py-10 sm:py-16">
      <Container>
        <div className="mb-2 sm:mb-4 flex items-center gap-2">
          <img src={iconGreen} loading="lazy" alt="cube" className="w-5" />
          <h3 className="text-xs font-medium text-text_secondary">
            A Little About Me
          </h3>
        </div>
        <div className="grid md:grid-cols-2 gap-4 sm:gap-6 md:gap-8">
          <h2 className="text-xl sm:text-3xl md:text-4xl font-medium leading-normal">
            Crafting Modern Web Experiences with Design, Code, and Innovation
          </h2>
          <div className="flex flex-col gap-4">
            <p className="text-text_secondary">
              I am Software engineer filled with passion and ambition to create
              clean and user friendly code. I take pride in solving complex real
              world problems with innovative solutions. Using my expertise in
              the MERN stack, I have created a record of high quality apps that
              exceed my clients expectations.
            </p>
            <p className="text-text_secondary">
              My toolbox of skills include Javascript, React, Next.js, Java,
              Python, MongoDB, Node.js, Express, Handlebar.js, MySQL, and
              Firebase. With my passion for software development I am constantly
              keeping up with industry best practices and using agile
              methodologies to succeed in such a competitive field.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
