import Container from "../components/Container";
import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="fixed w-full z-50">
      <Container className="mt-4">
        <div
          className="flex justify-between items-center border border-[#48484875] px-4 py-1.5 rounded-2xl"
          style={{ backdropFilter: "blur(30px)" }}
        >
          <Link
            to="/"
            aria-label="K."
            className="text-3xl font-bold hover:text-text_secondary duration-300"
          >
            K.
          </Link>
          <div className="flex gap-6 items-center">
            <a
              href="./resume.pdf"
              aria-label="about"
              className="hidden sm:block text-xs hover:text-text_secondary duration-300"
            >
              Resume
            </a>
            <a
              href="mailto:oj.kanz1@gmail.com"
              className="px-3 py-2 text-xs bg-text text-bg rounded-lg hover:bg-[#fff] transition-all duration-100"
            >
              Contact
            </a>
          </div>
        </div>
      </Container>
    </nav>
  );
}
